import React from 'react';
import styled from "styled-components";
import {black, greyLight, nunito, purple, sourceSansPro, white} from "../../Theme";
import {PlanOfActionTabs, Skills} from "../../constants/enums";
import {SaveButton} from "./PlanOfActionScreen";
import {useForm} from "react-hook-form";
import {isInReadOnlyMode} from "../../App";
import {device} from "../../constants/breakpoints";
import {PlanOfActionDesktopFooter} from './PlanOfActionFooter';
import {ReactComponent as ArrowRight} from "../../resources/images/ic-arrowRight.svg";
import {determineSkill} from "../../util/Helpers";
import {ScoreIndicatorIcon} from "./PlanOfActionOverview";
import {skills} from "../../constants/skills";
import {CheckItem} from "../../components/CheckItem";
import {useHistory} from "react-router-dom";

function QuestionsListCard({period, answers, activeTab, type, onSave, isLoading, checkItems, ...props}) {
    const {register, handleSubmit} = useForm();

    return (
        <StyledQuestionsListCard $type={type} $activeTab={activeTab}>
            <Inner>
                <QuestionsListCardRoot>
                    {type === PlanOfActionTabs.PLANOFACTION &&
                        (
                            <>
                                <ExecutiveFunctionsSummaryItem>
                                    <InfoItemTitle>Scores van jouw Executieve Functies</InfoItemTitle>
                                    <ItemText>
                                        Klik op onderstaande EF’s en kies wat jouw focuspunten zullen zijn komende periode. De focuspunten komen dan hieronder te staan en kun je beginnen aan je Plan van Aanpak.
                                    </ItemText>
                                </ExecutiveFunctionsSummaryItem>

                                <ExecutiveFunctionsSkillItemsWrapper>
                                    <ExecutiveFunctionsSkillItem period={period} title={"Startende EF's:"} skill={Skills.BEGINNER}/>
                                    <SummaryItemDivider/>
                                    <ExecutiveFunctionsSkillItem period={period} title={"Ontwikkelende EF's:"} skill={Skills.DEVELOPING}/>
                                    <SummaryItemDivider/>
                                    <ExecutiveFunctionsSkillItem period={period} title={"Beheerste EF's:"} skill={Skills.MASTERED}/>
                                </ExecutiveFunctionsSkillItemsWrapper>

                                <ExecutiveFunctionsFocusPointsSummaryItem>
                                    <SummaryItemHeader>
                                        <ItemTitle>Mijn focuspunten voor de komende periode zijn:</ItemTitle>
                                        <ItemSubtitle>Zorg dat je niet meer dan vier focuspunten hebt!</ItemSubtitle>
                                    </SummaryItemHeader>
                                    {checkItems?.length > 0 ?
                                        checkItems.map((checkItem) => {
                                            return (
                                                <CheckItem key={checkItem.id} checkItem={checkItem} isChecked={true}/>
                                            );
                                        })
                                        :
                                        <ItemSubtitle>Je hebt nog geen focuspunten ingesteld. Klik hierboven op een Executieve Functie om daar jouw focuspunten te kiezen. </ItemSubtitle>
                                    }

                                </ExecutiveFunctionsFocusPointsSummaryItem>
                            </>
                        )
                    }

                    {type === PlanOfActionTabs.PLANOFACTION
                        ?
                        answers !== null && period.planOfActionQuestions.sort((a, b) => {
                            return a.sortOrder - b.sortOrder
                        }).map((question, index) => {
                            let answer = answers.find(a => a.questionId === question.id);
                            return <QuestionItem key={question.id}>
                                <QuestionText>{index + 1}. {question.title}</QuestionText>
                                <Input defaultValue={answer ? answer.value : ""} maxLength={500} {...register(question.id)} disabled={isInReadOnlyMode()}/>
                            </QuestionItem>
                        })
                        :
                        answers !== null && period.evaluationQuestions.sort((a, b) => {
                            return a.sortOrder - b.sortOrder
                        }).map((question, index) => {
                            let answer = answers.find(a => a.questionId === question.id);
                            return (
                                <QuestionItem key={question.id}>
                                    <QuestionText>{index + 1} {question.title}</QuestionText>
                                    <Input defaultValue={answer ? answer.value : ""} maxLength={500} {...register(question.id)} disabled={isInReadOnlyMode()}/>
                                </QuestionItem>
                            )
                        })
                    }
                    {!isInReadOnlyMode() && <SaveButton onClick={handleSubmit(handleSaveButtonClick)} isLoading={isLoading}/>}
                </QuestionsListCardRoot>
            </Inner>
            <PlanOfActionDesktopFooter {...props} />
        </StyledQuestionsListCard>
    );

    function handleSaveButtonClick(data) {
        let answers = []
        Object.keys(data).forEach((questionId) => {
            let answer = answers.find(a => a.questionId === questionId);
            let answerData = {
                id: answer ? answer.id : null,
                questionId: questionId,
                periodId: period.id,
                value: data[questionId]
            }
            answers.push(answerData)
        });
        onSave(answers);
    }
}

function ExecutiveFunctionsSkillItem({period, title, skill}) {
    const history = useHistory();

    const filteredSkills = (period.scoresPerExecutiveFunction ?? [])?.filter(ef =>
        determineSkill(ef.totalScore, {beginner: period.beginner, developing: period.developing, mastered: period.mastered}).key === skill)

    const noEfText = `Je hebt voor deze periode geen ${title.charAt(0).toLowerCase() + title.slice(1, -1)}. Ga aan de slag met jouw ${
        skill === Skills.BEGINNER ? "ontwikkelende en beheerste" :
            skill === Skills.DEVELOPING ? "beginnende en beheerste" :
                skill === Skills.MASTERED ? "beginnende en ontwikkelende" : ""
    } EF's.`

    return (
        <ExecutiveFunctionsSkillItemRoot>
            <ItemRootHeader>
                <ScoreIndicatorIcon determinedSkill={skills[skill]}/>
                <ItemTitle>{title}</ItemTitle>
            </ItemRootHeader>

            <SummaryTagRow>
                {
                    filteredSkills.length > 0 ?
                        filteredSkills.map((ef, i) => (
                            <SummaryTag key={ef.title} $backgroundColor={ef.color} $hoverColor={ef.tertiaryColor} $hasDarkText={ef.hasDarkText} onClick={() => {
                                history.push("/executieve-functies/" + ef.id + `?periodId=${period.id}`, {activeTab: `?activeTab=${PlanOfActionTabs.PLANOFACTION}`})
                            }}>
                                <SummaryTagText>{ef.title}</SummaryTagText>
                                <ArrowRight fill={ef.hasDarkText ? black : white}/>
                            </SummaryTag>
                        ))
                    :
                        <ItemSubtitle>{noEfText}</ItemSubtitle>
                }
            </SummaryTagRow>
        </ExecutiveFunctionsSkillItemRoot>
    );
}

const StyledQuestionsListCard = styled.div`
    display: ${({$type, $activeTab}) => $type === $activeTab ? "flex" : "none"};
    flex-direction: column;
    width: 100%;
    max-width: 590px;
`;

const Inner = styled.div`
    background: ${white};
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 3px 3px 0 0;
    padding: 38px 67px;

    @media screen and ${device.tablet} {
        padding: 38px 47px;
    }

    @media screen and ${device.mobileL} {
        padding: 20px 23px;
    }
`;

const QuestionsListCardRoot = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const ExecutiveFunctionsSkillItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ExecutiveFunctionsSkillItemRoot = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ExecutiveFunctionsSummaryItem = styled(ExecutiveFunctionsSkillItemRoot)``;

const ExecutiveFunctionsFocusPointsSummaryItem = styled(ExecutiveFunctionsSkillItemRoot)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 25px 0;
`;

const ItemText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
`;

const ItemRootHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`;

const SummaryItemHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
`;

const InfoItemTitle = styled(ItemTitle)`
    font-size: 18px;
`;

const ItemSubtitle = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    font-style: italic;
    color: ${black};
    opacity: 0.7;
`;

const SummaryItemDivider = styled.div`
    width: 100%;
    height: 1px;
    border: 1px solid ${greyLight};
`;

const SummaryTagRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`;

const SummaryTag = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    border-radius: 3px;
    padding: 0 15px;
    gap: 5px;
    background-color: ${({$backgroundColor}) => $backgroundColor ?? purple};
    box-shadow: 0 2px 0 0 ${({$hoverColor}) => $hoverColor ?? purple};
    color: ${({$hasDarkText}) => $hasDarkText ? black : white};
    

    &:hover {
        cursor: pointer;
        background-color: ${({$hoverColor}) => $hoverColor ?? purple};
    }
`;

const SummaryTagText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
`;

const QuestionItem = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
`;

const QuestionText = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
    margin-bottom: 10px;
`;

const Input = styled.textarea`
    resize: none;
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
    padding: 5px 9px;
    width: 100%;
    border: 1px solid #E2E2EB;
    border-radius: 2px;
    height: 84px;
`;

export default QuestionsListCard;