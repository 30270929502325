import Swal from "sweetalert2";
import {black, purple, purpleDark, red, sourceSansPro, white} from "../Theme";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import closeIcon from "../resources/images/close.svg";

export const BasicPopup = withReactContent(Swal)

function showBasicPopup(title, text, buttonText) {
    BasicPopup.fire({
        html: (
            <CenteredPopupContent>
                <CloseButtonWrapper>
                    <CloseButton src={closeIcon} onClick={BasicPopup.clickConfirm}/>
                </CloseButtonWrapper>
                <PopupTitle>{title}</PopupTitle>
                <PopupText>{text}</PopupText>
                <ConfirmButton onClick={BasicPopup.clickConfirm}>{buttonText}</ConfirmButton>
            </CenteredPopupContent>
        ),
        heightAuto: false,
        showCancelButton: false,
        showConfirmButton: false,
    })
}

function showConfirmationPopup(title, text, positiveButtonText, positiveButtonCallback, negativeButtonText, negativeButtonCallback) {
    BasicPopup.fire({
        html: (
            <CenteredPopupContent>
                <ConfirmCloseButtonWrapper>
                    <CloseButton src={closeIcon} onClick={BasicPopup.clickCancel}/>
                </ConfirmCloseButtonWrapper>
                <PopupTitle>{title}</PopupTitle>
                <ConfirmPopupText>{text}</ConfirmPopupText>
                <ConfirmButton onClick={BasicPopup.clickConfirm}>{positiveButtonText}</ConfirmButton>
                {negativeButtonText && <CancelButton onClick={BasicPopup.clickCancel}>{negativeButtonText}</CancelButton>}
            </CenteredPopupContent>
        ),
        customClass: {
            popup: "swal2-popup-custom-confirm",
            htmlContainer: "swal2-popup-custom-confirm-container"
        },
        heightAuto: false,
        showCancelButton: false,
        showConfirmButton: false,
        padding: 0,

    }).then((result) => {
        if (result.isConfirmed) {
            positiveButtonCallback()
        } else {
            if(negativeButtonCallback) negativeButtonCallback()
        }
    })
}

export const CenteredPopupContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0;
`

export const CloseButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center; 
    gap: 16px;   
`

const ConfirmCloseButtonWrapper = styled(CloseButtonWrapper)`
    padding: 10px 10px 0 0;
`;

export const CloseButton = styled.img`
    src: ${props => props.src};
    cursor: pointer;
`

export const PopupTitle = styled.div`
    font-family: ${sourceSansPro};
    font-size: 1.125em;
    font-weight: 600;
    color: ${red};
    margin-top: 12px;
`

export const PopupText = styled.div`
    font-family: ${sourceSansPro};
    color: ${black};
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
`

const ConfirmPopupText = styled(PopupText)`
    padding: 0 20px;
`

export const ConfirmButton = styled.button`
    background: ${purple};
    font-family: ${sourceSansPro};
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    color: ${white};
    width: 160px;
    height: 38px;
    border: none;
    margin-top: 20px;
    border-bottom: ${red} 3px;
    border-radius: 3px;
    box-shadow: 0 2px ${purpleDark};
`

export const CancelButton = styled.button`
    background: none;
    font-family: ${sourceSansPro};
    font-size: 14px;
    cursor: pointer;
    color: ${black};
    width: 160px;
    height: 38px;
    border: none;
    margin: 4px 0 6px 0;
    border-bottom: ${red} 3px;
    border-radius: 3px;
`

export default showBasicPopup;
export { showConfirmationPopup }