import {useEffect, useState} from 'react';
import AppStorage, {StorageKey, useAppStorageState} from "../../util/AppStorage";

export function useIsUserAuthenticated() {
    const [initialUserIsAuthenticated, setInitialUserIsAuthenticated] = useState(Boolean(AppStorage.get(StorageKey.USER_ID) && AppStorage.get(StorageKey.API_ACCESS_TOKEN) && AppStorage.get(StorageKey.USER_TYPE)));
    const [userID,] = useAppStorageState(StorageKey.USER_ID);
    const [userType,] = useAppStorageState(StorageKey.USER_TYPE);
    const [accessToken,] = useAppStorageState(StorageKey.API_ACCESS_TOKEN);

    useEffect(() => {
            if (userID && userType && accessToken) {
                setInitialUserIsAuthenticated(true)
            } else {
                setInitialUserIsAuthenticated(false)
            }
            // eslint-disable-next-line
        }, [userID, accessToken, userType]
    );

    return initialUserIsAuthenticated;
}