import {isInReadOnlyMode} from "../App";
import checkLogoBlack from "../resources/images/checkBlack.svg";
import checkLogoWhite from "../resources/images/checkWhite.svg";
import Api from "../util/Api";
import AppStorage, {StorageKey} from "../util/AppStorage";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {black, sourceSansPro} from "../Theme";

export function CheckItem({checkItem, isChecked, updateCheckedItems = null, executiveFunction = null, periodId = null}) {
    // When checkItem is used in PlanOfAction, executiveFunction is NULL and the user should not be able to check and uncheck an item
    const shouldHandleCheck = executiveFunction !== null
    const color = executiveFunction?.color ? executiveFunction?.color : checkItem.color;
    const secondaryColor = executiveFunction?.secondaryColor ? executiveFunction?.secondaryColor : checkItem.secondaryColor;
    const hasDarkText = executiveFunction?.hasDarkText ? executiveFunction?.hasDarkText : checkItem.hasDarkText
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(false)
    }, [isChecked]);

    return (
        <CheckItemRoot $shouldHandleCheck={shouldHandleCheck} onClick={toggleIsChecked} disabled={isInReadOnlyMode() || isLoading}>
            <CheckItemCheckWrapper active={isChecked} color={color} secondaryColor={secondaryColor}>
                {isChecked && <CheckItemCheck src={hasDarkText ? checkLogoBlack : checkLogoWhite}/>}
            </CheckItemCheckWrapper>
            <CheckItemTitle>{checkItem.title}</CheckItemTitle>
        </CheckItemRoot>
    );

    function toggleIsChecked() {
        if (!shouldHandleCheck || isLoading) return
        setIsLoading(true)

        const config = {
            data: {
                "pupilId": AppStorage.get(StorageKey.PUPIL_ID),
                "periodId": periodId,
                "executiveFunctionCheckItemId": checkItem.id,
            }
        }

        if (isChecked) {
            Api.delete(`pupils/${AppStorage.get(StorageKey.PUPIL_ID)}/checkItem/?pupilId=${AppStorage.get(StorageKey.PUPIL_ID)}&periodId=${periodId}&executiveFunctionCheckItemId=${checkItem.id}`,
                onSuccess, null, null, null, config);
        } else {
            Api.post("pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/checkItem/", null,
                onSuccess, null, null, null, config);
        }
    }

    function onSuccess() {
        if (updateCheckedItems) {
            updateCheckedItems(periodId)
        }
    }
}

export const CheckItemRoot = styled.div`
    pointer-events: ${props => props.disabled ? 'none' : 'default'};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    cursor: ${({$shouldHandleCheck}) => $shouldHandleCheck ? "pointer" : "auto"};
`;

export const CheckItemCheckWrapper = styled.div`
    background-color: ${props => props.active ? props.color : props.secondaryColor};
    min-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border: 1px solid ${props => props.active ? props.color : "#0000001A"};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckItemCheck = styled.img`
    width: 16px;
    height: 16px;
`;

export const CheckItemTitle = styled.div`
    font-family: ${sourceSansPro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: ${black};
`;
