import {useEffect, useState} from "react";
import styled from "styled-components";
import {nunito, purpleDark, red} from "../Theme";
import calenderLogo from "../resources/images/calendar.svg";
import chartLogo from "../resources/images/chart.svg";
import bulbLogo from "../resources/images/bulb.svg";
import scienceLogo from "../resources/images/science.svg";
import moreLogo from "../resources/images/more.svg";
import {Link, useLocation} from "react-router-dom";
import {RoutesWithoutNav, useQuery} from "../App";
import {useGlobalState} from "../util/GlobalState";
import {ShowTaskPopup} from "../popups/showTaskPopup";
import {BasicPopup} from "../popups/showBasicPopup";
import {ShowExecutiveFunctionProgressPopup} from "../popups/showExecutiveFunctionProgressPopup";
import {ShowImagePopup} from "../popups/showImagePopup";
import {OnboardingPopup} from "../popups/showOnboardingPopup";
import {TasksFinishedPopup} from "../popups/showTasksFinishedPopup";
import {WhatsNewPopup} from "../popups/whatsNewPopup";
import {useMenuDataContext} from '../contexts/MenuDataContext';
import {checkRequiredFeatureFlags} from '../util/Helpers';
import {FeatureFlags} from '../constants/featureFlags';
import {ReactComponent as PremiumIcon} from '../resources/images/premium.svg';
import {StorageKey, useAppStorageState} from '../util/AppStorage';

function BottomMenu() {
    const {featureFlags} = useMenuDataContext();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [isVisible, setVisible] = useState(false);
    const [isMentorView, setIsMentorView] = useGlobalState('isMentorView', false);
    const [, setNative] = useGlobalState('isNative', false)
    const query = useQuery();
    const [finishedOnboarding] = useAppStorageState(StorageKey.FINISHED_ONBOARDING)

    useEffect(() => {
        if (RoutesWithoutNav.includes(location.pathname)) {
            setVisible(false);
        } else {
            setVisible(true);
        }
        setCurrentLocation(location.pathname);

        closePopupIfActive()

        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (location?.pathname?.startsWith("/mentor/")) {
            setIsMentorView(true)
        } else {
            setIsMentorView(false)
        }
        //eslint-disable-next-line
    }, [location.pathname])

    // set ref so native apps can hide the bottomMenu through the AppInterface
    useEffect(() => {
        if (query.get("app")) {
            setNative(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <BottomMenuWrapper $visible={isVisible}>
            {isMentorView ? (
                <MenuItemList>
                    <MenuItem
                        src={chartLogo}
                        text={"EF in beeld"}
                        isActive={currentLocation.startsWith("/mentor/ef-voortgang")}
                        to={"/mentor/ef-voortgang"}
                        location={location}
                    />
                    <MenuItem
                        src={calenderLogo}
                        text={"Planagenda"}
                        isActive={currentLocation === "/mentor/planagenda"}
                        to={"/mentor/planagenda"}
                        location={location}
                        showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                    />
                    <MenuItem
                        src={moreLogo}
                        text={"Handleiding"}
                        isActive={currentLocation === "/mentor/meer"}
                        to={"/mentor/meer"}
                        location={location}
                    />
                </MenuItemList>
            ) : (
                <MenuItemList>
                    <MenuItem
                        src={chartLogo}
                        text={"EF in beeld"}
                        isActive={currentLocation.includes("/plan-van-aanpak") || currentLocation.startsWith("/ef-voortgang")}
                        to={"/ef-voortgang"}
                        location={location}
                    />
                    <MenuItem
                        src={bulbLogo}
                        text={"EF's"}
                        isActive={currentLocation.includes("/executieve-functies")}
                        to={"/executieve-functies"}
                        location={location}
                    />
                    <MenuItem
                        src={calenderLogo}
                        text={"Planagenda"}
                        isActive={currentLocation === "/" || currentLocation === "/planagenda"}
                        to={"/planagenda"}
                        location={location}
                        showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                    />
                    <MenuItem
                        src={scienceLogo}
                        text={"Toetsweek"}
                        isActive={currentLocation === "/toetsweek"}
                        to={"/toetsweek"}
                        location={location}
                        showPremiumIcon={!checkRequiredFeatureFlags(featureFlags, FeatureFlags.FEATURE_SCHEDULE)}
                    />
                    <MenuItem
                        src={moreLogo}
                        text={"Handleiding"}
                        isActive={currentLocation.includes("/meer")}
                        to={"/meer"}
                        location={location}
                    />
                </MenuItemList>
            )}
        </BottomMenuWrapper>
    )

    function closePopupIfActive() {
        if (!finishedOnboarding) return;

        const popups = [
            BasicPopup,
            ShowExecutiveFunctionProgressPopup,
            ShowImagePopup,
            OnboardingPopup,
            ShowTaskPopup,
            TasksFinishedPopup,
            WhatsNewPopup
        ]

        popups.forEach(popup => {
            if (!!popup) {
                popup.close()
            }
        })
    }
}

function MenuItem({showPremiumIcon, ...props}) {
    return (
        <MenuItemWrapper to={props.to} $isActive={props.isActive}>
            <MenuItemLogoWrapper>
                <MenuItemLogo src={props.src}/>
                {showPremiumIcon && <StyledPremiumIcon />}
            </MenuItemLogoWrapper>
            <MenuItemText>{props.text}</MenuItemText>
        </MenuItemWrapper>
    );
}

const BottomMenuWrapper = styled.div`
    background: ${purpleDark};
    width: 100%;
    height: 60px;
    display: ${props => props.$visible ? "flex" : "none"};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const MenuItemList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;

const MenuItemWrapper = styled(Link)`
    text-decoration: none;
    background: ${props => props.$isActive ? red : undefined};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 48px;
    width: 64px;
`;

const MenuItemLogoWrapper = styled.div`
    position: relative;
`;

const MenuItemLogo = styled.img`
    color: white;
    display: block;
    height: 24px;
    width: 24px;
    margin-top: 3px;
`;

const StyledPremiumIcon = styled(PremiumIcon)`
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 0;
    right: -8px;
`;

const MenuItemText = styled.h1`
    color: white;
    font-family: ${nunito};
    font-style: normal;
    font-size: 10px;
`;

export default BottomMenu;