import {BackgroundedScreen, black, nunito, purple, purpleDark, SmallHeader, SmallHeaderTitle, sourceSansPro, white} from '../../Theme';
import BaseScreen from '../base/BaseScreen';
import styled from 'styled-components';
import {device} from '../../constants/breakpoints';
import {useQuery} from '../../App';
import {Link, useHistory} from 'react-router-dom';
import qs from 'qs';
import {useEffect, useState} from 'react';
import Api from '../../util/Api';
import showBasicPopup from '../../popups/showBasicPopup';
import AppStorage, {StorageKey} from '../../util/AppStorage';
import {ConfigurableLoader, Loader, LoaderOverlay} from '../../components/Loader';
import {HiddenLabel, SingleRow} from './Partials/SingleRow';
import {skills} from '../../constants/skills';
import {Skills} from '../../constants/enums';
import {FilterSelect} from "../../components/Select/FilterSelect";
import {useGlobalState} from "../../util/GlobalState";

const StyledBackgroundedScreen = styled(BackgroundedScreen)`
    display: flex;
    flex-direction: column;
`;

const StyledSmallHeader = styled(SmallHeader)`
    flex-shrink: 0;
`;

// Note that these styled-components are also used in AllPeriodsCard
export const Container = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;

    @media screen and ${device.laptop} {
        padding: 0 20px;
        overflow-x: auto;
    }

    @media screen and ${device.tablet} {
        height: 100%;
    }
`;

export const ScrollableContainer = styled.div`
    min-width: 768px;
`;

const FiltersWrapper = styled.div`
    background: rgba(94, 65, 135, 0.07);
    width: 100%;
    padding: 15px 30px 15px 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and ${device.laptop} {
        align-items: flex-start;
        flex-direction: column;
    }
`;

const Filters = styled.div`
    display: flex;
    gap: 16px;
    flex: 1;

    @media screen and ${device.laptop} {
        width: 100%;
    }
`;

const FilterBox = styled.div`
    flex: 0 1 ${({$basis}) => $basis};
`;

const Advertisement = styled.p`
    flex: 1;
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    color: ${black};
    line-height: 20px;
    overflow: hidden;
    text-align: right;
    max-width: 427px;
    margin: 0;

    a {
        color: ${purple};
        text-decoration: underline;
        font-size: 16px;
        line-height: 1.5;
    }

    @media screen and ${device.laptopL} {
        max-width: 300px;
    }

    @media screen and ${device.laptop} {
        text-align: left;
        max-width: unset;
    }
`;

export const ResultsWrapper = styled.div`
    margin-top: 20px;
    background: ${white};
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgba(86, 89, 146, 0.1);
    box-shadow: 0 2px 0 0 rgba(86, 89, 146, 0.1);
    position: relative;
`;

export const Records = styled.div`
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const ScoringLabels = styled.div`
    position: sticky;
    bottom: 0;
    background: ${white};
    box-shadow: 0 -4px 24px 0 rgba(94, 65, 135, 0.07);
    width: 100%;
    padding: 0 30px;
`;

export const ScoringLabelsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 120px;
`;

export const ScoringLabelsBar = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 31px repeat(${({$maxScore}) => $maxScore}, 1fr);
`;

export const ScoringLabelBox = styled.div`
    grid-column: ${({$column}) => $column};
    position: relative;
    height: 52px;
`;

export const ScoringLabel = styled.p`
    font-style: ${nunito};
    font-weight: 700;
    color: ${purpleDark};
    position: absolute;
    right: 0;
    transform: translateX(50%);
    text-align: center;
    white-space: nowrap;
`;

export const Record = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const RecordTitle = styled(Link)`
    text-align: right;
    flex: 0 0 100px;
    font-style: ${sourceSansPro};
    font-weight: 700;
    text-decoration: underline;
    color: ${purpleDark};
    margin: 0;
    cursor: pointer;
    overflow-wrap: anywhere;

    &:hover {
        text-decoration: none;
        color: ${purpleDark};
    }
`;

export const RowWrapper = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;

export const Rows = styled.div`
    flex: 1;
`;

const StyledLoader = styled(ConfigurableLoader)`
    top: 60px;
    transform: translateX(-50%);
    z-index: 1;
`;

export const EmptyState = styled.div`
    padding: 40px 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    min-height: 250px;

    p {
        font-family: ${sourceSansPro};
        font-weight: 400;
        font-size: 16px;
        margin: 0;
        text-align: center;
        max-width: 240px;
        color: ${purple};
    }
`;

export const EmptyStateTitle = styled.h2`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-align: center;
    color: ${purple};
`;

function TeacherScheduleScreen() {
    const query = useQuery();
    const history = useHistory();

    const [data, setData] = useState(null);
    const [isLoadingFilterOptions, setIsLoadingFilterOptions] = useState(true);
    const [isLoadingNewData, setIsLoadingNewData] = useState(true);
    const [filterOptions, setFilterOptions] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const [hasNoGrades, setHasNoGrades] = useState(false);
    const [selectedGrade] = useGlobalState("selectedGrade")

    // Get filter options
    useEffect(() => {
        const controller = new AbortController();
        getFilterOptions(controller.signal);
        return () => controller.abort();
    }, []);

    useEffect(() => {
        if (window.location.href.indexOf("/mentor/ef-voortgang") > -1) {
            AppStorage.remove(StorageKey.PUPIL_ID)
            AppStorage.remove(StorageKey.PUPIL_TITLE)
        }
        // eslint-disable-next-line
    }, [window.location])

    const grade = query.get("grade");
    const period = query.get("period");
    const ef = query.get("ef");

    // Default options for the filters
    const getDefaultPeriodOption = () => period ? (filterOptions?.periods?.find(p => p.value === period) || filterOptions?.periods?.[0]) : filterOptions?.periods?.[0];
    const getDefaultExecutiveFuctionOption = () => ef ? (filterOptions?.efs?.find(e => e.value === ef) || filterOptions?.efs?.[1]) : filterOptions?.efs?.[1];

    // When selecting an option, add the correct filter values. Initially, when filters are not dirty, add the default EF filter (that is NOT "all EFs")
    const handleSelectOption = (selectedOption, param) => {
        const currentParams = {
            grade: grade || AppStorage.get(StorageKey.GRADE_ID),
            period,
            ef: isDirty ? ef : (ef || getDefaultExecutiveFuctionOption()?.value)
        };
        const updatedParams = {...currentParams, [param]: selectedOption?.value}
        const queryString = qs.stringify(updatedParams, {skipNulls: true});
        setIsDirty(true)
        history.push({search: queryString})
    }

    const selectedPeriodId = period;
    const selectedEfId = ef ? (ef !== "all" && ef) : getDefaultExecutiveFuctionOption()?.value

    // After filterOptions have been fetched and also when queryParams change, fetch the data with filters
    useEffect(() => {
        const controller = new AbortController();

        if (filterOptions?.grades?.length > 0) {
            setHasNoGrades(false);
            getData(controller.signal, {
                'filter[gradeId]': AppStorage.get(StorageKey.GRADE_ID),
                ...(selectedPeriodId && {'filter[periodId]': selectedPeriodId}),
                ...(selectedEfId && {'filter[efId]': selectedEfId}),
            });
        }

        // If user has no grades, show message
        if (!filterOptions?.grades) {
            setHasNoGrades(true);
        }

        return () => controller.abort();
        //eslint-disable-next-line
    }, [filterOptions, grade, period, ef, selectedGrade]);

    // Update selectedEfId after data is fetched, to keep stale EF visible while loading
    const [newSelectedEfId, setNewSelectedEfId] = useState(selectedEfId);
    useEffect(() => {
        if (!isLoadingNewData && (selectedEfId !== newSelectedEfId)) {
            setNewSelectedEfId(selectedEfId);
        }
        //eslint-disable-next-line
    }, [data, isLoadingNewData])

    const content = (
        <StyledBackgroundedScreen>
            <StyledSmallHeader>
                <SmallHeaderTitle>EF in beeld</SmallHeaderTitle>
            </StyledSmallHeader>

            {!isLoadingFilterOptions ? (
                <Container>
                    <ScrollableContainer>
                        {hasNoGrades ? (
                            <ResultsWrapper>
                                <EmptyState>
                                    <EmptyStateTitle>Geen klassen gevonden</EmptyStateTitle>
                                    <p>Het lijkt erop dat je niet de mentor bent van een klas, waardoor er geen resultaten getoond kunnen worden.</p>
                                </EmptyState>
                            </ResultsWrapper>
                        ) : (
                            <>
                                <FiltersWrapper>
                                    <Filters>
                                        {filterOptions?.periods?.length > 0 && (
                                            <FilterBox $basis="160px">
                                                <FilterSelect
                                                    key={period}
                                                    options={filterOptions.periods}
                                                    placeholder="Periodes"
                                                    onChange={(selectedOption) => handleSelectOption(selectedOption, "period")}
                                                    defaultValue={getDefaultPeriodOption}
                                                />
                                            </FilterBox>
                                        )}

                                        {filterOptions?.efs?.length > 0 && (
                                            <FilterBox $basis="250px">
                                                <FilterSelect
                                                    key={ef}
                                                    options={filterOptions.efs}
                                                    placeholder="Executieve Functies"
                                                    onChange={(selectedOption) => handleSelectOption(selectedOption, "ef")}
                                                    defaultValue={getDefaultExecutiveFuctionOption}
                                                />
                                            </FilterBox>
                                        )}
                                    </Filters>

                                    <Advertisement>Inzetten op de startende EF binnen je klas? Kijk dan naar onze materialen en methode op onze site <a href="https://onderwijscentrumopnij.nl/" target="_blank" rel="noreferrer">onderwijscentrumopnij.nl</a></Advertisement>
                                </FiltersWrapper>

                                <ResultsWrapper>
                                    {data?.length > 0 ? (
                                        <>
                                            <Records>
                                                {Boolean(newSelectedEfId) ? (
                                                    data?.map(record => (
                                                        <Record key={record.id}>
                                                            <RecordTitle
                                                                to={"/ef-voortgang"}
                                                                onClick={() => navigateToPupilProgress(record)}
                                                            >
                                                                {record.title}
                                                            </RecordTitle>
                                                            <Rows>
                                                                {record?.periods?.map((periodData, i, {length}) => (
                                                                    <RowWrapper key={periodData.id}>
                                                                        <SingleRow
                                                                            periodData={periodData}
                                                                            selectedEfId={newSelectedEfId}
                                                                            isLast={i + 1 === length}
                                                                        />
                                                                    </RowWrapper>
                                                                ))}
                                                            </Rows>
                                                        </Record>
                                                    ))
                                                ) : (
                                                    transformDataForEf(data)?.map(ef => (
                                                        <Record key={ef.id}>
                                                            <RecordTitle
                                                                as="p"
                                                                onClick={() => handleSelectOption({value: ef.id, label: ef.title}, "ef")}
                                                            >
                                                                {ef.title}
                                                            </RecordTitle>
                                                            <Rows>
                                                                {ef?.periods?.map((period, i, {length}) => (
                                                                    <RowWrapper key={period.id}>
                                                                        <SingleRow
                                                                            periodData={data?.[0]?.periods?.[0]}
                                                                            selectedEfId={newSelectedEfId}
                                                                            data={period}
                                                                            isLast={i + 1 === length}
                                                                        />
                                                                    </RowWrapper>
                                                                ))}
                                                            </Rows>
                                                        </Record>
                                                    ))
                                                )}
                                            </Records>

                                            <ScoringLabels>
                                                <ScoringLabelsRow>
                                                    <HiddenLabel/>
                                                    <ScoringLabelsBar $maxScore={getMaxScore(data)}>
                                                        <ScoringLabelBox $column={getSkills(data)?.[Skills.BEGINNER]?.column}>
                                                            <ScoringLabel>{getSkills(data)?.[Skills.BEGINNER]?.label}</ScoringLabel>
                                                        </ScoringLabelBox>
                                                        <ScoringLabelBox $column={getSkills(data)?.[Skills.DEVELOPING]?.column}>
                                                            <ScoringLabel>{getSkills(data)?.[Skills.DEVELOPING]?.label}</ScoringLabel>
                                                        </ScoringLabelBox>
                                                        <ScoringLabelBox $column={getSkills(data)?.[Skills.MASTERED]?.column}>
                                                            <ScoringLabel>{getSkills(data)?.[Skills.MASTERED]?.label}</ScoringLabel>
                                                        </ScoringLabelBox>
                                                    </ScoringLabelsBar>
                                                </ScoringLabelsRow>
                                            </ScoringLabels>
                                        </>
                                    ) : (
                                        <EmptyState>
                                            {!isLoadingNewData &&
                                                <>
                                                    <EmptyStateTitle>Geen resultaten gevonden</EmptyStateTitle>
                                                    <p>Er zijn geen resultaten gevonden. Probeer de filters te wijzigen.</p>
                                                </>
                                            }
                                        </EmptyState>
                                    )}

                                    {isLoadingNewData &&
                                        <LoaderOverlay>
                                            <StyledLoader delay={false}/>
                                        </LoaderOverlay>
                                    }
                                </ResultsWrapper>
                            </>
                        )}

                    </ScrollableContainer>
                </Container>
            ) : (
                <Loader/>
            )}
        </StyledBackgroundedScreen>
    )

    return (
        <BaseScreen
            activeMenuItem="EF in beeld"
            content={content}
        />
    );

    // Fetch the filter options and append "All" options to it, where "Alle periodes" is default but "Alle Executieve Functies" is NOT default
    function getFilterOptions(signal) {
        setIsLoadingFilterOptions(true);

        Api.get(`persons/${AppStorage.get(StorageKey.USER_ID)}/progress/filterOptions`, (response) => {
            const extendedFilters = {
                ...response?.data,
                periods: [
                    {value: null, label: "Alle periodes"}, ...response?.data?.periods,
                ],
                efs: [
                    {value: "all", label: "Alle Executieve Functies"}, ...response?.data?.efs,
                ],
            }
            setFilterOptions(extendedFilters)
            setIsLoadingFilterOptions(false)
        }, null, null, () => {
            setIsLoadingFilterOptions(false)
            showBasicPopup("Ophalen van filter opties mislukt!", "Er is iets mis gegaan met het ophalen van de filter opties. Probeer het zometeen nog een keer.", "OK")
        }, {signal})
    }

    // Get data based on filters in query params
    function getData(signal, params) {
        setIsLoadingNewData(true);

        const config = {
            params: params,
            signal
        }

        Api.get(`persons/${AppStorage.get(StorageKey.USER_ID)}/progress`, (response) => {
            setData(response?.data?.data)
            setIsLoadingNewData(false)
        }, null, null, () => {
            setIsLoadingNewData(false)
            showBasicPopup("Ophalen van data mislukt!", "Er is iets mis gegaan met het ophalen van de data. Probeer het zometeen nog een keer.", "OK")
        }, config)
    }

    function navigateToPupilProgress(user) {
        AppStorage.set(StorageKey.PUPIL_ID, user.id)
        AppStorage.set(StorageKey.PUPIL_TITLE, user.firstname + (user.surname ? (" " + user.surname) : ""))
    }

    // If you've selected "Alle Executieve Functies" transform the data to the correct structure
    function transformDataForEf(data) {
        const transformedData = [];
        // Loop through each item in the data array
        data?.forEach(item => {
            item?.periods?.forEach(period => {
                // Loop through each executive function score per period
                period?.scoresPerExecutiveFunction?.forEach(execFunction => {
                    // Find or create the executive function obj
                    let execFunctionGroup = transformedData?.find(f => f.id === execFunction.id);
                    if (!execFunctionGroup) {
                        execFunctionGroup = {
                            title: execFunction.title,
                            id: execFunction.id,
                            periods: []
                        };
                        transformedData.push(execFunctionGroup);
                    }

                    // Find or create the period within the executive function obj
                    let periodGroup = execFunctionGroup?.periods?.find(p => p.id === period.id);
                    if (!periodGroup) {
                        periodGroup = {
                            id: period.id,
                            title: period.title,
                            pupils: []
                        };
                        execFunctionGroup?.periods?.push(periodGroup);
                    }

                    // Add the pupil score data to the period's pupils array
                    const pupilData = {
                        userId: item.id,
                        title: item.title,
                        score: execFunction.totalScore || execFunction.score || null,
                        averageScore: execFunction.averageScore || null
                    };

                    periodGroup?.pupils?.push(pupilData);
                });
            });
        });

        return transformedData;
    }
}

// Helper function to get the correct columns for the scoring labels
export function getSkills(resolvedData) {
    const periodData = resolvedData?.[0]?.periods?.[0];

    const minScore = periodData?.mastered / 4;

    const beginnerColumn = 1;
    const developingColumn = (periodData?.beginner + 1) - minScore + 1;
    const masteredColumn = (periodData?.developing + 1) - minScore + 1;

    skills[Skills.BEGINNER].column = beginnerColumn;
    skills[Skills.DEVELOPING].column = developingColumn;
    skills[Skills.MASTERED].column = masteredColumn;

    return skills;
}

// Helper function to get the max score for the scoring labels
export function getMaxScore(resolvedData) {
    const periodData = resolvedData?.[0]?.periods?.[0];
    const minScore = periodData?.mastered / 4;
    return periodData?.mastered - minScore;
}

export default TeacherScheduleScreen;