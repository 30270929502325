import React, {useEffect, useState} from 'react';
import BaseScreen from "../base/BaseScreen";
import {BrainImage} from "../../components/BrainImage"
import {black, nunito, purple, purpleLight, purpleLighter, red, sourceSansPro, white} from "../../Theme";
import {ReactComponent as closeIcon} from "../../resources/images/ic-closePurple.svg";
import forwardIcon from "../../resources/images/forwardWhite.svg";
import backIcon from "../../resources/images/ic-arrowLeft.svg"
import styled, {css} from "styled-components";
import {device} from "../../constants/breakpoints";
import Api from "../../util/Api";
import AppStorage, {StorageKey} from "../../util/AppStorage";
import {useHistory} from "react-router-dom";
import QuestionAnswerRow from "./QuestionAnswerRow";
import {Loader} from "../../components/Loader";
import {useGlobalState} from "../../util/GlobalState";
import {showNativeNavigation} from "../../AppInterface";
import {darken} from "polished";
import {showConfirmationPopup} from "../../popups/showBasicPopup";

function PlanOfActionQuestionnaire(props) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [steps, setSteps] = useState([]);
    const [answers, setAnswers] = useState(null);
    const [editedAnswers, setEditedAnswers] = useState({})
    const [isNative] = useGlobalState('isNative', false)
    const [showError, setShowError] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const history = useHistory();
    const periodId = (history.location.state && history.location.state.period) ? history.location.state.period.id : props.match.params.id;

    useEffect(() => {
        showNativeNavigation();
        getQuestionnaire();
        // eslint-disable-next-line
    }, [])

    const lastStep = currentStepIndex === (steps.length - 1);
    const firstStep = currentStepIndex === 0;
    const step = steps[currentStepIndex];

    const content = (
        <PlanOfActionQuestionnaireRoot
            $color={step?.efColor ?? purpleLighter}
            $darkColor={step?.efColor ? darken(0.05, step.efColor) : purpleLight}
        >
            {isLoading &&
                <Loader/>
            }

            {steps.length !== 0 && answers !== null &&
                <React.Fragment>
                    <QuestionnaireWrapper>
                        <QuestionnaireHeader>
                            <QuestionnaireTitle
                                $stepType={step.type}
                                $hasDarkText={step.hasDarkText}
                            >
                                Vragenlijst {props?.location?.state?.period?.title}
                            </QuestionnaireTitle>
                            <CancelButton
                                alt={"Sluiten"}
                                onClick={() => handleCloseQuestionnaire()}
                                $stepType={step.type}
                                $hasDarkText={step.hasDarkText}
                            />
                        </QuestionnaireHeader>

                        <QuestionnaireCard>
                            <CardTitleWrapper $type={step?.type ?? "text"}>
                                {step.type === "questions" ? (
                                    <PageNumber $efColor={step?.efColor ?? purpleLight} $hasDarkText={step?.hasDarkText ?? false}>{currentStepIndex}</PageNumber>
                                ) : (
                                    <StyledBrainImage executiveFunction={null}/>
                                )}
                                <CardTitle>{step.title}</CardTitle>

                                {step.type === "questions" && <BrainImage executiveFunction={step?.ef ?? null}/>}
                            </CardTitleWrapper>

                            {showError &&
                                <QuestionnaireError>Vul alle vragen in om door te gaan naar de volgende EF.</QuestionnaireError>
                            }

                            {step.type === "questions"
                                ?
                                <QuestionsList>
                                    {steps[currentStepIndex].questions.sort((a, b) => {
                                        return a.sortOrder - b.sortOrder
                                    }).map((question, index) => {
                                        const answer = answers.find(a => a.questionId === question.id);
                                        return <QuestionWrapper key={question.id}>
                                            <QuestionTitle>{question.title}</QuestionTitle>
                                            <QuestionAnswerRow
                                                question={question}
                                                answer={answer}
                                                periodId={periodId}
                                                step={step}
                                                onPut={(oldAnswer, newAnswer) => handleNewAnswerGiven(oldAnswer, newAnswer)}
                                            />
                                        </QuestionWrapper>
                                    })}
                                </QuestionsList>
                                :
                                <CardText>
                                    {step.text}
                                </CardText>
                            }
                        </QuestionnaireCard>

                        <DesktopFooter/>
                    </QuestionnaireWrapper>
                    <TabletFooter/>
                </React.Fragment>
            }
        </PlanOfActionQuestionnaireRoot>
    );

    return (
        <BaseScreen
            activeMenuItem={"EF in beeld"}
            content={content}
        />
    )

    function handleCloseQuestionnaire() {
        if (editedAnswers[currentStepIndex]) {
            showConfirmationPopup(
                "Weet je het zeker?",
                "Weet je zeker dat je wil stoppen met het invullen van de vragenlijst? Jouw antwoorden worden niet opgeslagen tot je naar de volgende stap gaat.",
                "Vragenlijst afsluiten",
                () => history.push("/ef-voortgang"),
                "Annuleren")
        } else {
            history.push("/ef-voortgang")
        }
    }

    function DesktopFooter() {
        return <DesktopQuestionnaireFooter isNative={isNative}>
            <FooterContent/>
        </DesktopQuestionnaireFooter>
    }

    function TabletFooter() {
        return <TabletQuestionnaireFooter isNative={isNative}>
            <FooterContent/>
        </TabletQuestionnaireFooter>
    }

    function FooterContent() {
        return <React.Fragment>
            <StepIndicator>Stap {currentStepIndex + 1} van {steps.length}</StepIndicator>

            <NextStepButton onClick={() => handleNextStepButtonClick()}>
                <NextStepButtonText>{lastStep ? "Afronden" : "Volgende"}</NextStepButtonText>
                {!lastStep && <NextStepButtonIcon src={forwardIcon} alt={"Pijltje naar rechts"}/>}
            </NextStepButton>

            {!firstStep &&
                <PreviousStepButton onClick={() => handlePreviousStepButtonClick()}>
                    <PreviousStepButtonIcon src={backIcon} alt={"Pijltje naar links"}/>
                    <PreviousStepButtonText>Vorige</PreviousStepButtonText>
                </PreviousStepButton>
            }
        </React.Fragment>
    }

    function getCurrentStepAnswers() {
        let stepAnswers = []

        // Get all current step answers
        steps[currentStepIndex].questions.forEach((question) => {
            const answer = answers.find(a => a.questionId === question.id)
            stepAnswers.push(answer)
        });

        return stepAnswers
    }

    function handleNextStepButtonClick() {
        const stepAnswers = getCurrentStepAnswers()

        // Check if any answers is not filled in
        if (!stepAnswers.includes(undefined)) {
            if (!lastStep) {
                putData()
                // Go to next step
                setShowError(false)
                setCurrentStepIndex(currentStepIndex + 1);
            } else {
                if (AppStorage.get(StorageKey.USER_TYPE) === "PUPIL") {
                    showConfirmationPopup(
                        "Vragenlijst afronden?",
                        "Weet je zeker dat je de vragenlijst wil afronden? Je krijgt gelijk je score te zien en je kunt aan de slag met jouw focuspunten.",
                        "Afronden",
                        () => {
                            putData()
                            history.push("/ef-voortgang")
                        },
                        "Annuleren")
                } else {
                    history.push("/ef-voortgang")
                }
            }
        } else {
            setShowError(true)
        }
    }

    function putData() {
        const stepAnswers = getCurrentStepAnswers()

        if (stepAnswers.length > 0) {
            const userUuid = AppStorage.get(StorageKey.USER_ID);
            const config = {
                params: {
                    'filter[periodId]': props.periodId
                }
            }

            // Put new data
            if (editedAnswers[currentStepIndex]) {
                if (AppStorage.get(StorageKey.USER_TYPE) === "PUPIL") {
                    Api.put("/pupils/" + userUuid + "/answers", stepAnswers, () => {
                        setEditedAnswers((prevState) => ({
                            ...prevState,
                            [currentStepIndex]: false
                        }));
                    }, null, null, null, config);
                }
            }
        }
    }

    function handlePreviousStepButtonClick() {
        setShowError(false)

        if (!firstStep) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    }

    function getQuestionnaire() {
        const config = {
            params: {
                'context[personId]': AppStorage.get(StorageKey.PUPIL_ID) //call contains information generated on logged in member or context person
            }
        }
        Api.get("/questionnairePages", (response) => {
            let sortedResponse = response.data.sort((a, b) => {
                return a.sortOrder - b.sortOrder
            });
            setSteps(sortedResponse);

            let edits = {}
            sortedResponse.forEach((step, index) => {
                edits[index] = false
            })
            setEditedAnswers(edits)
            getAnswers(sortedResponse);
        }, null, null, null, config);
    }

    function getAnswers(steps) {
        const config = {
            params: {
                'filter[periodId]': periodId,
            }
        }

        Api.get("/pupils/" + AppStorage.get(StorageKey.PUPIL_ID) + "/answers", (response) => {
            setAnswers(response.data);
            setStepToLastUpdated(steps, response.data);
        }, null, null, null, config);
    }

    function handleNewAnswerGiven(oldAnswer, newAnswer) {
        setEditedAnswers((prevState) => ({
            ...prevState,
            [currentStepIndex]: true
        }));

        if (oldAnswer) {
            const oldAnswerInAnswers = answers.find(a => a.id === oldAnswer.id)
            if (oldAnswerInAnswers === undefined) {
                addNewAnswer(newAnswer)
            } else {
                replaceOldAnswerWithNewAnswer(oldAnswer, newAnswer)
            }
        } else {
            addNewAnswer(newAnswer)
        }
    }

    function addNewAnswer(newAnswer) {
        // add to local state if there was no previous value
        setAnswers([...answers, newAnswer])
    }

    function replaceOldAnswerWithNewAnswer(oldAnswer, newAnswer) {
        // replace old answer with new answer in local answers state
        let answersCopy = [...answers]
        let oldAnswerIndex = answersCopy.findIndex(answer => {
            return answer.questionId === oldAnswer.questionId
        })
        if (oldAnswerIndex >= 0) {
            answersCopy[oldAnswerIndex] = newAnswer
            setAnswers(answersCopy)
        } else {
            addNewAnswer(newAnswer)
        }
    }

    function setStepToLastUpdated(localSteps, localAnswers) {
        localSteps.some((step, stepIndex) => {
            if (localAnswers.length === 0) return setLoading(false);

            return step.questions.some((question) => {
                if (!localAnswers.find(a => a.questionId === question.id)) {
                    setCurrentStepIndex(stepIndex);
                    setLoading(false)
                    return true;
                }
                setLoading(false)
                return false;
            });
        });
    }
}

export default PlanOfActionQuestionnaire;

export const PlanOfActionQuestionnaireRoot = styled.div`
    width: 100%;
    min-height: 100%;
    position: relative;
    background: linear-gradient(${({$darkColor}) => $darkColor} 250px, ${({$color}) => $color} 250px);
    display: flex;
    flex-direction: column;
`;

export const QuestionnaireWrapper = styled.div`
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
    padding: 55px 17px 110px 17px;

    @media screen and ${device.tablet} {
        overflow-y: auto;
        flex: 1 1 0;
    }

    @media screen and ${device.mobileL} {
        padding: 10px 17px 110px 17px;
    }
`;

export const QuestionnaireHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 27px;
`;

export const CardTitleWrapper = styled.section`
    width: 100%;
    display: flex;
    ${({$type}) => $type === "text" ? css`
        flex-direction: column;
    ` : css`
        align-items: center;
    `};
    gap: 20px;
`;

export const PageNumber = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({$efColor}) => $efColor};
    color: ${({$hasDarkText}) => $hasDarkText ? black : white};
`;

export const QuestionnaireTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 28px;
    color: ${({$stepType, $hasDarkText}) => $stepType === 'text' ? purple : $hasDarkText ? black : white};

    @media screen and ${device.mobileL} {
        font-size: 18px;
    }
`;

export const CancelButton = styled(closeIcon)`
    cursor: pointer;
    position: absolute;
    right: 0;

    path {
        fill: ${({$stepType, $hasDarkText}) => $stepType === 'text' ? purple : $hasDarkText ? black : white};
`;

export const QuestionnaireCard = styled.div`
    width: 100%;
    padding: 35px 65px;
    background: ${white};
    border: solid rgba(86, 89, 146, 0.1);
    border-width: 1px 1px 0 1px;
    border-radius: 3px;

    @media screen and ${device.mobileL} {
        padding: 20px 15px;
    }
`;

export const CardTitle = styled.h2`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 21px;
    color: ${black};
    margin: 0;
`;

export const CardText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${black};
`;

export const DesktopQuestionnaireFooter = styled.div`
    width: 100%;
    height: 60px;
    background: ${purpleLighter};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
    border-radius: 0px 0px 3px 3px;

    @media screen and ${device.tablet} {
        display: none;
    }
`;

export const TabletQuestionnaireFooter = styled.div`
    display: none;

    @media screen and ${device.tablet} {
        width: 100%;
        height: 60px;
        background: ${purpleLighter};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0px 2px 0px rgba(86, 89, 146, 0.1);
        border-radius: 0px 0px 3px 3px;
        flex: 0 0 auto;
    }
`;

export const StepIndicator = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 400;
    font-size: 12px;
    color: ${purple};
`;

export const NextStepButton = styled.div`
    width: 125px;
    height: 37px;
    background: ${purple};
    box-shadow: 0px 2px 0px #4D2F78;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    position: absolute;
    right: 14px;
    cursor: pointer;
`;

export const NextStepButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${white};
`;

export const NextStepButtonIcon = styled.img`
    width: 17px;
`;

export const PreviousStepButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    position: absolute;
    left: 20px;
    padding: 10px 15px;
    cursor: pointer;
`;

export const PreviousStepButtonText = styled.div`
    font-family: ${sourceSansPro};
    font-weight: 600;
    font-size: 15px;
    color: ${purple};
`;

export const PreviousStepButtonIcon = styled.img`
    width: 17px;
`;

export const QuestionsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding-bottom: 10px;
`;

export const QuestionWrapper = styled.div`

`;

export const QuestionTitle = styled.div`
    font-family: ${nunito};
    font-weight: 700;
    font-size: 16px;
    color: ${black};
    margin-bottom: 11px;
`;

const StyledBrainImage = styled(BrainImage)`
    width: 200px;
    height: 200px;
`;

const QuestionnaireError = styled.p`
    font-family: ${sourceSansPro};
    font-weight: 700;
    color: ${red};
    margin-bottom: 30px;

`;